<template>
  <div style="text-align: center">
    <iframe
      :src="`https://herehome.sa/mada.php?id=${id}&itemId=${itemId}`"
      frameborder="0"
      style="height: 523px; width: 60vw"
      v-if="type == 'mada'"
    ></iframe>
    <iframe
      :src="`https://herehome.sa/stc.php?id=${id}&itemId=${itemId}`"
      frameborder="0"
      style="height: 523px; width: 60vw"
      v-else-if="type == 'stc'"
    ></iframe>

    <iframe
      :src="`https://herehome.sa/tabby.php?id=${id}&itemId=${itemId}`"
      frameborder="0"
      style="height: 523px; width: 60vw"
      v-else-if="type == 'tabby'"
    ></iframe>

    <iframe
      v-else
      :src="`https://herehome.sa/visa.php?id=${id}&itemId=${itemId}`"
      frameborder="0"
      style="height: 523px; width: 60vw"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      itemId: null,

      typa: null
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.itemId = this.$route.params.itemId;

    this.type = this.$route.params.type;
  }
};
</script>
<style></style>
